@font-face {
  font-family: "M+1m";
  src: url("mplus-1m-thin.woff");
  font-weight: 200;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "M+1m";
  src: url("mplus-1m-light.woff");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "M+1m";
  src: url("mplus-1m-regular.woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "M+1m";
  src: url("mplus-1m-medium.woff");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "M+1m";
  src: url("mplus-1m-bold.woff");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
